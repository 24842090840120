import { Component, OnInit } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { UserService } from '../services/user.service';
import { StylistService } from '../services';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Router } from '@angular/router';
import { Intercom } from '@capacitor-community/intercom';

declare var window: any;
declare var posthog: any;
@Component({
  selector: 'app-help',
  templateUrl: './help.page.html',
  styleUrls: ['./help.page.scss'],
})
export class HelpPage implements OnInit{


  displayIntercom: boolean;
  checked: boolean;
  firstLoad: boolean = true;
  constructor(
    //private navController: NavController,
    private userService: UserService,
    private stylistService: StylistService,
    private platform: Platform,
    //private navParams: NavParams,
    //private viewController: ViewController,
    private modalController: ModalController,
    private router: Router,
    private inAppBrowser: InAppBrowser
  ) {
  }

  public ionViewWillEnter() {
    this.displayIntercom = localStorage.getItem("displayIntercom") == "false" ? false : true;
    this.checked = this.displayIntercom;
    // if the toggle is checked it will trigger the onchange when the modal first loads - we don't want that to happen.
    // if(this.checked) {
    //   this.firstLoad = true
    // } else {
    //   this.firstLoad = false
    // }
  }

  public ngOnInit() {

  }



  public onDismiss() {
    this.modalController.dismiss();
  }

  public redirectToHelpCenterLink() {
    const url = `https://support.salonmonster.com`;
    //const url = `https://salonmonster.crisp.help/en-ca/`;
    if (this.platform.is('capacitor')) {
      //this.inAppBrowser.create(url, '_system');
    } else {
      const win = window.open(url, '_blank');
      win.focus();
    }
  }

  public showChat() {
    if (window && window['Intercom']) {
      const intercom = window['Intercom'];
      intercom('show');
    }
    if(this.platform.is("capacitor")){
      Intercom.displayMessenger();
    }
  }

  public toggleChatVisibility(event) {
    // if the toggle is checked it will trigger the onchange when the modal first loads - we don't want that to happen.    
    // if(this.firstLoad) {
    //   this.firstLoad = false;
    //   return
    // }
    localStorage.setItem("displayIntercom", this.displayIntercom + "");

    if(window && window["Intercom"]) {
        const intercom = window['Intercom'];

        intercom('update', {
          hide_default_launcher: !this.displayIntercom
        });
      }
    

    if(this.platform.is("capacitor")) {
      if(this.displayIntercom) {
        Intercom.displayLauncher()
      } else {
        Intercom.hideLauncher()
      }
    }
  }
}
